
/* eslint-disable no-underscore-dangle */

import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import InputField from '@/components/Form/InputField.vue'
import Select from '@/components/Form/Select.vue'
import Toggle from '@/components/Form/Toggle.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { COLLECTIONS, db } from '@/firebase'
import { IProjectTypeV3 } from '@/types/resources-version-3'
import { sortBy } from 'lodash-es'
import moment from 'moment'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import Dialog from '@/components/Modals/Dialog.vue'
import RequisitionFilesDialog from '@/components/Modals/Dialogs/RequisitionFilesDialog.vue'
import { getItemsWithSamePropertyValue } from '@/globals/javascript/utils/helpers'
import { mixWB } from '@/globals/javascript/utils/mixins'

export interface IAllProjectsRowData {
  projectID: string
  accountID: string
  accountName: string
  userName: string
  caseNumber: string
  productID: string
  productName: string
  projectType: string
  address: string
  countryCode: string
  statusID: string
  createdAtText: string
  createdAtMiliSec: number
  lastUpdatedText: string
  lastUpdatedMiliSec: number
  flow: string
  unitsCount: number
  buildingsCount: number
  requisitionsCount: number
  requisitionFiles: any[]
  registrationsCount: number
  materialsCount: number
  coatingsCount: number
  samplesCount: number
  standardSamplesCount: number
  iGroupsCount: number
  gGroupsCount: number
  roomsCount: number
  placesCount: number
  labelIDPrefix: string
}

export default defineComponent({
  name: 'AllProjects',
  data() {
    return {
      allRawProjects: [] as any[],
      selectedPageID: 'saas-account',
      allProjects: [] as IAllProjectsRowData[],
      selectedAccountID: '',
      selectedProductID: '',
      selectedStatusID: '',
      selectedDateTypeID: 'createdAt',
      selectedDatePeriodID: '',
      selectedStartDate: '',
      selectedEndDate: '',
      toggles: [
        { id: 'projectID', title: this.mixWB('W: Projekt ID'), isSelected: false },
        { id: 'projectType', title: this.mixWB('W: Projekttype'), isSelected: false },
        { id: 'userName', title: this.mixWB('W: Bruger'), isSelected: false },
        { id: 'address', title: this.mixWB('W: Adresse'), isSelected: false },
        { id: 'countryCode', title: this.mixWB('W: Land'), isSelected: false },
        { id: 'flow', title: this.mixWB('W: Flow'), isSelected: false },
        { id: 'unitsCount', title: this.mixWB('W: Enheder'), isSelected: false },
        { id: 'requisitionsCount', title: this.mixWB('W: Rekvisitioner'), isSelected: false },
        { id: 'materialsCount', title: this.mixWB('W: Materialer'), isSelected: false },
        { id: 'samplesCount', title: this.mixWB('W: Prøver'), isSelected: false },
        { id: 'otherCount', title: this.mixWB('W: Andre tal'), isSelected: false },
      ],

      // Dialog
      showDialog: false,
      dialogProps: {
        projectData: {} as any,
      },
      RequisitionFilesDialog: shallowRef(RequisitionFilesDialog),
    }
  },
  computed: {
    ...mapGetters(['accountsAsObject', 'usersAsObject', 'projectTypesAsArrayV3']),
    warnings() {
      const warnings: string[] = []
      const projectWithSameNumberIDs = getItemsWithSamePropertyValue(this.allProjects as IAllProjectsRowData[], 'labelIDPrefix')
      projectWithSameNumberIDs.forEach((projects) => {
        if (projects[0].labelIDPrefix === undefined || projects[0].labelIDPrefix === '') {
          return
        }
        warnings.push(
          mixWB('%s projekter benytter samme label ID prefix (%s)',
            [projects.length.toString(), projects[0].labelIDPrefix]),
        )
      })
      return warnings
    },
    listTitle(): string {
      let title = this.mixWB('W: Milva rådgivning')

      if (this.selectedPageID === 'saas-account') {
        title = this.mixWB('W: SaaS konti')
      }
      if (this.selectedPageID === 'saas-solo') {
        title = this.mixWB('W: SaaS solo')
      }

      title += ` (${ this.rows.length })`

      return title
    },
    filteredProjects() {
      const projects: IAllProjectsRowData[] = this.allProjects

      return projects.filter((x) => {
        if (this.selectedPageID === 'saas-solo' && !x.accountID) {
          return true
        }
        if (this.selectedPageID === 'milva' && x.accountID === 'bpZulvjI5VnFL9zVO0zb') {
          return true
        }
        if (this.selectedPageID === 'saas-account' && x.accountID && !['bpZulvjI5VnFL9zVO0zb'].includes(x.accountID)) {
          return true
        }

        return false
      })
    },
    filterAccountOptions() {
      let options: {[key:string]: string}[] = []

      this.filteredProjects.forEach((project) => {
        const already = options.find((x) => x.key === project.accountID)

        if (already) {
          return
        }

        options.push({
          key: project.accountID,
          value: project.accountName,
        })
      })

      options = sortBy(options, ['value'])

      options.unshift({
        key: '',
        value: this.mixWB('ALL'),
      })

      return options
    },
    filterProductOptions() {
      return [
        { key: '', value: this.mixWB('ALL') },
        { key: 'mapper', value: this.mixWB('W: Mapper') },
        { key: 'sampler', value: this.mixWB('W: Sampler') },
      ]
    },
    filterStatusOptions() {
      return [
        { key: '', value: this.mixWB('ALL') },
        { key: 'not-started', value: this.mixWB('STATUS_NOT_STARTED') },
        { key: 'started', value: this.mixWB('STATUS_STARTED') },
        { key: 'requisition', value: this.mixWB('W: Send rekvisition') },
        { key: 'analyses', value: this.mixWB('STATUS_AWAITING_TEST_RESULTS') },
        { key: 'files', value: this.mixWB('W: Upload filer') },
        { key: 'awaiting-completion', value: this.mixWB('W: Færdiggør projekt') },
        { key: 'report-ready', value: this.mixWB('STATUS_REPORT_READY') },
      ]
    },
    filterDateTypeOptions() {
      return [
        { key: 'createdAt', value: this.mixWB('W: Oprettet') },
        { key: 'lastUpdated', value: this.mixWB('W: Opdateret') },
      ]
    },
    filterDatePeriodOptions() {
      return [
        { key: '', value: this.mixWB('W: Altid') },
        { key: '1', value: this.mixWB('W: Sidste måned') },
        { key: '3', value: this.mixWB('W: Sidste 3 måneder') },
        { key: '6', value: this.mixWB('W: Sidste 6 måneder') },
        { key: '12', value: this.mixWB('W: Sidste 12 måneder') },
        { key: 'range', value: this.mixWB('W: Vælg selv') },
      ]
    },
    columns() {
      const test: any = this.toggles
      const togglesAsObject = test.reduce((prev: any, item: any) => {
        prev[item.id] = item.isSelected
        return prev
      }, {})

      return [
        {
          key: 'accountName',
          title: this.mixWB('W: Konto'),
          canSort: true,
        },
        {
          key: 'projectID',
          title: this.mixWB('W: Projekt ID'),
          canSort: true,
          hide: !togglesAsObject.projectID,
        },
        {
          key: 'caseNumber',
          title: this.mixWB('W: Sagsnummer'),
          canSort: true,
        },
        {
          key: 'productName',
          title: this.mixWB('W: Produkt'),
          canSort: true,
        },
        {
          key: 'projectType',
          title: this.mixWB('W: Projekttype'),
          canSort: true,
          hide: !togglesAsObject.projectType,
        },
        {
          key: 'userName',
          title: this.mixWB('W: Bruger'),
          canSort: true,
          hide: !togglesAsObject.userName,
        },
        {
          key: 'address',
          title: this.mixWB('W: Adresse'),
          canSort: true,
          hide: !togglesAsObject.address,
        },
        {
          key: 'countryCode',
          title: this.mixWB('W: Land'),
          canSort: true,
          hide: !togglesAsObject.countryCode,
        },
        {
          key: 'statusID',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
        {
          key: 'createdAtMiliSec',
          title: this.mixWB('W: Oprettet'),
          canSort: true,
        },
        {
          key: 'lastUpdatedMiliSec',
          title: this.mixWB('W: Opdateret'),
          canSort: true,
        },
        {
          key: 'flow',
          title: this.mixWB('W: Flow'),
          canSort: true,
          hide: !togglesAsObject.flow,
        },
        {
          key: 'unitsCount',
          title: this.mixWB('W: Enheder'),
          canSort: true,
          hide: !togglesAsObject.unitsCount,
          size: 'small',
        },
        {
          key: 'requisitionsCount',
          title: this.mixWB('W: Revisitioner'),
          canSort: true,
          hide: !togglesAsObject.requisitionsCount,
        },
        {
          key: 'buildingsCount',
          title: this.mixWB('W: Bygninger'),
          canSort: true,
          hide: !togglesAsObject.unitsCount,
          size: 'small',
        },
        {
          key: 'registrationsCount',
          title: this.mixWB('W: Registreringer'),
          canSort: true,
          hide: !togglesAsObject.materialsCount,
          size: 'small',
        },
        {
          key: 'materialsCount',
          title: this.mixWB('W: Materialer'),
          canSort: true,
          hide: !togglesAsObject.materialsCount,
          size: 'small',
        },
        {
          key: 'coatingsCount',
          title: this.mixWB('W: Overfladebehandlinger'),
          canSort: true,
          hide: !togglesAsObject.materialsCount,
          size: 'small',
        },
        {
          key: 'samplesCount',
          title: this.mixWB('W: Prøver'),
          canSort: true,
          hide: !togglesAsObject.samplesCount,
          size: 'small',
        },
        {
          key: 'standardSamplesCount',
          title: this.mixWB('W: Prøveudtag'),
          canSort: true,
          hide: !togglesAsObject.samplesCount,
          size: 'small',
        },
        {
          key: 'iGroupsCount',
          title: this.mixWB('W: Sammenkoblinger'),
          canSort: true,
          hide: !togglesAsObject.otherCount,
          size: 'small',
        },
        {
          key: 'gGroupsCount',
          title: this.mixWB('W: Grupper'),
          canSort: true,
          hide: !togglesAsObject.otherCount,
          size: 'small',
        },
        {
          key: 'roomsCount',
          title: this.mixWB('W: Rum'),
          canSort: true,
          hide: !togglesAsObject.otherCount,
          size: 'small',
        },
        {
          key: 'placesCount',
          title: this.mixWB('W: Steder'),
          canSort: true,
          hide: !togglesAsObject.otherCount,
          size: 'small',
        },
      ]
    },
    rows(): Row[] {
      const rows: Row[] = []

      let projects: IAllProjectsRowData[] = this.filteredProjects

      // Filter by selected account
      if (this.selectedAccountID) {
        projects = projects.filter((x) => x.accountID === this.selectedAccountID)
      }

      // Filter by product
      if (this.selectedProductID) {
        projects = projects.filter((x) => x.productID === this.selectedProductID)
      }

      // Filter by status
      if (this.selectedStatusID) {
        projects = projects.filter((x) => x.statusID === this.selectedStatusID)
      }

      // Filter by date
      if (this.selectedDatePeriodID === 'range') {
        let start = 0
        if (this.selectedStartDate) {
          const startDate = new Date(this.selectedStartDate)
          const startTime = startDate.getTime()
          if (startTime) {
            start = startTime
          }
        }

        let end = new Date().getTime()
        if (this.selectedEndDate) {
          const endDate = new Date(this.selectedEndDate)

          if (endDate.getTime()) {
            endDate.setHours(endDate.getHours() + 24)
            end = endDate.getTime()
          }
        }

        let key: 'createdAtMiliSec' | 'lastUpdatedMiliSec' = 'createdAtMiliSec'
        if (this.selectedDateTypeID === 'lastUpdated') {
          key = 'lastUpdatedMiliSec'
        }

        projects = projects.filter((x) => x[key] > start && x[key] < end)
      }
      else if (this.selectedDatePeriodID) {
        const fromDate = new Date()
        fromDate.setMonth(fromDate.getMonth() - Number(this.selectedDatePeriodID))

        let key: 'createdAtMiliSec' | 'lastUpdatedMiliSec' = 'createdAtMiliSec'
        if (this.selectedDateTypeID === 'lastUpdated') {
          key = 'lastUpdatedMiliSec'
        }

        projects = projects.filter((x) => x[key] > fromDate.getTime())
      }

      projects.forEach((projectItem) => {
        rows.push({
          projectID: projectItem.projectID,
          accountID: projectItem.accountID,
          accountName: projectItem.accountName,
          caseNumber: projectItem.caseNumber,
          productID: projectItem.productID,
          productName: projectItem.productName,
          projectType: projectItem.projectType,
          userName: projectItem.userName,
          address: projectItem.address,
          countryCode: projectItem.countryCode,
          statusID: projectItem.statusID,
          createdAtMiliSec: projectItem.createdAtMiliSec,
          createdAtText: projectItem.createdAtText,
          lastUpdatedMiliSec: projectItem.lastUpdatedMiliSec,
          lastUpdatedText: projectItem.lastUpdatedText,
          flow: projectItem.flow,
          unitsCount: projectItem.unitsCount,
          requisitionsCount: projectItem.requisitionsCount,
          buildingsCount: projectItem.buildingsCount,
          registrationsCount: projectItem.registrationsCount,
          materialsCount: projectItem.materialsCount,
          coatingsCount: projectItem.coatingsCount,
          samplesCount: projectItem.samplesCount,
          standardSamplesCount: projectItem.standardSamplesCount,
          iGroupsCount: projectItem.iGroupsCount,
          gGroupsCount: projectItem.gGroupsCount,
          roomsCount: projectItem.roomsCount,
          placesCount: projectItem.placesCount,

        })
      })

      return rows
    },
  },
  methods: {
    async getAllProjectsOnLoad() {
      const projectsResult = await db.collection(COLLECTIONS.DB_PROJECTS).get()

      const allProjects: IAllProjectsRowData[] = []

      projectsResult.docs.forEach((doc: any) => {
        const projectData = doc.data()

        // Ommit showcase projects
        if (projectData?.options?.isShowcaseProject) {
          return
        }

        this.allRawProjects.push(projectData)

        const account = this.accountsAsObject[projectData.accountID]
        const userIDToUse = projectData.createdBy ?? projectData.createdByUserID
        const user = this.usersAsObject[userIDToUse]
        const productID = projectData?.projectType?.id === 'sampler' ? 'sampler' : 'mapper'
        const productName = productID === 'sampler' ? this.mixWB('W: Sampler') : this.mixWB('W: Mapper')
        let projectType = '-'
        if (productID === 'mapper') {
          const projectTypeItem = this.projectTypesAsArrayV3.find(
            (x: IProjectTypeV3) => x.id === projectData?.projectType?.id,
          )

          projectType = projectTypeItem ? this.mixWB(projectTypeItem.translation) : this.mixWB('W: Ukendt')
        }

        if (projectData.accountID && !account) {
          return
        }

        let caseNumberText = projectData.caseNumber
        if (projectData.customCaseNumber) {
          caseNumberText += ` // ${ projectData.customCaseNumber }`
        }
        const address = `${ projectData?.address?.address }, ${ projectData?.address?.postalCode } ${ projectData?.address?.city }`

        // Project dates
        // - Created at
        let createdAtMiliSec = 0
        if (typeof projectData?.timestamps?.createdAt === 'number') {
          createdAtMiliSec = projectData?.timestamps?.createdAt
        }
        else {
          const createdAtObject = projectData?.timestamps?.createdAt
          let createdAtDate = createdAtObject?.toDate?.()
          if (!createdAtDate) {
            createdAtDate = new Date(1970, 0, 1) // Epoch
            if (createdAtObject?._seconds) {
              createdAtDate.setSeconds(createdAtObject?._seconds)
            }
          }
          createdAtMiliSec = createdAtDate.getTime()
        }
        const createdAtText = moment(createdAtMiliSec).locale('da-DK').format('D. MMM. YYYY [kl.] HH:mm')

        // - Last updated
        let lastUpdatedMiliSec = 0
        if (typeof projectData?.timestamps?.lastUpdated === 'number') {
          lastUpdatedMiliSec = projectData?.timestamps?.lastUpdated
        }
        else {
          const lastUpdatedObject = projectData?.timestamps?.lastUpdated
          let lastUpdatedDate = lastUpdatedObject?.toDate?.()
          if (!lastUpdatedDate) {
            lastUpdatedDate = new Date(1970, 0, 1) // Epoch
            if (lastUpdatedObject?._seconds) {
              lastUpdatedDate.setSeconds(lastUpdatedObject?._seconds)
            }
          }
          lastUpdatedMiliSec = lastUpdatedDate.getTime()
        }
        const lastUpdatedText = moment(lastUpdatedMiliSec).locale('da-DK').format('D. MMM. YYYY [kl.] HH:mm')

        // Project numbers
        const counts = {
          units: {
            selectedUnits: projectData?.selectedUnits?.noOfUnits || 0,
            selectedBuildings: projectData?.selectedUnits?.noOfBuildings || 0,
          },
          samples: {
            total: projectData?.samplesCount?.total || 0,
            standard: projectData?.samplesCount?.standard || 0,
          },
          materials: {
            total: projectData?.materialsCount?.total || 0,
            materials: projectData?.materialsCount?.materials || 0,
            coatings: projectData?.materialsCount?.coatings || 0,
          },
          other: {
            iGroups: projectData?.otherCount?.iGroups || 0,
            gGroups: projectData?.otherCount?.gGroups || 0,
            rooms: projectData?.otherCount?.rooms || 0,
            places: projectData?.otherCount?.places || 0,
          },
        }

        // Flow
        const flow = projectData?.projectFlowType || 'step-by-step'

        const project: IAllProjectsRowData = {
          projectID: doc.id,
          accountID: account?.id ?? '',
          accountName: account?.name ?? this.mixWB('W: - ingen konto -'),
          userName: user?.fullName ?? this.mixWB('W: Ikke navngivet'),
          caseNumber: caseNumberText,
          productID,
          productName,
          projectType,
          address,
          countryCode: projectData.countryCode ?? 'DK',
          statusID: this.getStatusData(projectData).id,
          createdAtText: createdAtText || '-',
          createdAtMiliSec: createdAtMiliSec || 0,
          lastUpdatedText: lastUpdatedText || '-',
          lastUpdatedMiliSec: lastUpdatedMiliSec || 0,
          labelIDPrefix: projectData.labelIDPrefix,
          flow,
          unitsCount: counts.units.selectedUnits,
          buildingsCount: counts.units.selectedBuildings,
          requisitionsCount: projectData?.files?.requisitions?.length || 0,
          requisitionFiles: projectData?.files?.requisitions || [],
          registrationsCount: counts.materials.total,
          materialsCount: counts.materials.materials,
          coatingsCount: counts.materials.coatings,
          samplesCount: counts.samples.total,
          standardSamplesCount: counts.samples.standard,
          iGroupsCount: counts.other.iGroups,
          gGroupsCount: counts.other.gGroups,
          roomsCount: counts.other.rooms,
          placesCount: counts.other.places,
        }

        allProjects.push(project)
      })

      this.allProjects = allProjects
    },
    getStatusData(projectData: any) {
      const data = {
        id: '',
        text: '',
        class: '',
      }

      // Not started
      if (!projectData.options.isStarted) {
        data.id = 'not-started'
        data.text = this.mixWB('STATUS_NOT_STARTED')
        data.class = 'FlowClass1'
      }
      // Started
      else if (!projectData.options.isMappingCompleted) {
        data.id = 'started'
        data.text = this.mixWB('STATUS_STARTED')
        data.class = 'FlowClass1'
      }
      // Requisition
      else if (
        !projectData.options.areAllAnalysesUploaded
      && !projectData.files.requisitions.length
      ) {
        data.id = 'requisition'
        data.text = this.mixWB('W: Send rekvisition')
        data.class = 'FlowClass2'
      }
      // Analyses
      else if (!projectData.options.areAllAnalysesUploaded) {
        data.id = 'analyses'
        data.text = this.mixWB('STATUS_AWAITING_TEST_RESULTS')
        data.class = 'FlowClass2'
      }
      // Files
      else if (projectData.samplesCount.standard && !projectData.options.areAllFilesUploaded) {
        data.id = 'files'
        data.text = this.mixWB('W: Upload filer')
        data.class = 'FlowClass2'
      }
      // Awaiting project completion
      else if (!projectData.options.isProjectCompleted) {
        data.id = 'awaiting-completion'
        data.text = this.mixWB('W: Færdiggør projekt')
        data.class = 'FlowClass2'
      }
      // Report ready
      else {
        data.id = 'report-ready'
        data.text = this.mixWB('STATUS_REPORT_READY')
        data.class = 'FlowClass3'
      }

      return data
    },
    getStatusText(statusID: string): string {
      if (statusID === 'not-started') {
        return this.mixWB('STATUS_NOT_STARTED')
      }
      if (statusID === 'started') {
        return this.mixWB('STATUS_STARTED')
      }
      if (statusID === 'requisition') {
        return this.mixWB('W: Send rekvisition')
      }
      if (statusID === 'analyses') {
        return this.mixWB('STATUS_AWAITING_TEST_RESULTS')
      }
      if (statusID === 'files') {
        return this.mixWB('W: Upload filer')
      }
      if (statusID === 'awaiting-completion') {
        return this.mixWB('W: Færdiggør projekt')
      }
      if (statusID === 'report-ready') {
        return this.mixWB('STATUS_REPORT_READY')
      }
      return this.mixWB('UNKNOWN')
    },
    onPageSelect(pageID: string) {
      this.selectedAccountID = ''
      this.selectedPageID = pageID
    },
    onFilterPeriodUpdate() {
      this.selectedStartDate = ''
      this.selectedEndDate = ''
    },
    onSelectAllClick() {
      this.toggles.forEach((item) => {
        item.isSelected = true
      })
    },
    onDeselecAllClick() {
      this.toggles.forEach((item) => {
        item.isSelected = false
      })
    },
    onShowRequisistions(projectID: string) {
      const project = this.allProjects.find((x) => x.projectID === projectID)

      this.dialogProps.projectData = project

      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Select,
    Toggle,
    InputField,
    Dialog,
  },
  created() {
    this.getAllProjectsOnLoad()
  },
})
